<app-loading *ngIf="isLoading; else conteudo" [textLoading]="txtAuth"></app-loading>

<ng-template #conteudo [ngSwitch]="isAuthenticated">
  <div class="k-container">
    <div class="principal">
      <div *ngSwitchCase="true">
        <div>
          <h4 class="titulo">{{ language.title }}</h4>
        </div>

        <div class="ipt ipt-unidade">
          <div class="btn-unidade">
            <button
              type="button"
              class="btn-unidade__buscar"
              [class.btnCarregando]="botaoCarregando"
              (click)="buscarUnidades($event)">
              <i
                [class]="iconeBotaoBuscarUnidade"
                [class.iconMargin]="!botaoCarregando"
                *ngIf="showIconBuscar"></i><span> {{ unidadeInput }} </span>
            </button>
          </div>
          <app-input-error
            *ngIf="isCenterError"
            [textError]="language.errorCenter"></app-input-error>
        </div>

        <form [formGroup]="formLead" (ngSubmit)="proximaPagina()">
          <div class="ipt">
            <label class="ipt__label"> {{ language.labelCenter }}</label>
            <input
              class="ipt__campo"
              formControlName="centerId"
              type="text"
              pattern="\d*"
              maxlength="8"
              readonly />
            <div
              *ngIf="
                formLead.get('centerId')?.invalid &&
                (formLead.get('centerId')?.dirty || formLead.get('centerId')?.touched)
              ">
              <!-- <app-input-error
                *ngIf="formLead.get('centerId')?.errors?.['required']"
                [textError]="language.errorRequired"></app-input-error> -->
            </div>
          </div>

          <div class="ipt">
            <label class="ipt__label"> {{ language.labelFullname }} </label>
            <input
              class="ipt__campo"
              formControlName="nome"
              type="text"
              maxlength="60"
              (keypress)="alphaOnly($event)" />
            <div
              *ngIf="
                formLead.get('nome')?.invalid &&
                (formLead.get('nome')?.dirty || formLead.get('nome')?.touched)
              ">
              <app-input-error
                *ngIf="formLead.get('nome')?.errors?.['required']"
                [textError]="language.errorRequired"></app-input-error>
              <!-- <app-input-error
                *ngIf="formLead.get('nome')?.errors?.['pattern']"
                [textError]="language.errorFullname"></app-input-error> -->
            </div>
          </div>

          <div class="ipt">
            <label class="ipt__label"> {{ language.labelPhone }} </label>
            <input
              class="ipt__campo"
              formControlName="telefone"
              type="text"
              [mask]="language.phoneFormat"
              inputmode="numeric" />
            <div
              *ngIf="
                formLead.get('telefone')?.invalid &&
                (formLead.get('telefone')?.dirty || formLead.get('telefone')?.touched)
              ">
              <app-input-error
                *ngIf="formLead.get('telefone')?.errors?.['required']"
                [textError]="language.errorRequired"></app-input-error>
              <app-input-error
                *ngIf="formLead.get('telefone')?.errors?.minlength"
                [textError]="language.errorPhone"></app-input-error>
            </div>
          </div>

          <div class="ipt">
            <label class="ipt__label"> {{ language.labelEmail }} </label>
            <input class="ipt__campo" formControlName="email" type="email" autocapitalize="none" />
            <div
              *ngIf="
                formLead.get('email')?.invalid &&
                (formLead.get('email')?.dirty || formLead.get('email')?.touched)
              ">
              <!-- <app-input-error
                *ngIf="formLead.get('email')?.errors?.['required']"
                [textError]="language.errorRequired"></app-input-error> -->
              <app-input-error
                *ngIf="formLead.get('email')?.errors?.['pattern']"
                [textError]="language.errorEmail"></app-input-error>
            </div>
          </div>

          <div *ngIf="chamadaOk">
            <label class="ipt__label">Hash code</label>
            <div class="hash">
              <code> {{ displayHash }} </code>
            </div>

            <a [routerLink]="['']" [queryParams]="{ hash: displayHash }">Seguir para o Consentimento</a>
          </div>

          <div class="navigate-button">
            <button
              class="botaoContinuar"
              type="button"
              (click)="proximaPagina()"
              [class.btnInativo]="!formLead.valid"
              [class.btnCarregando]="botaoCadastrarCarregando">
              <i [class]="iconeBotaoCadastraLead"></i>
              <span> {{ language.buttonRegister }} </span>
            </button>
          </div>
        </form>
      </div>

      <app-message
        *ngSwitchCase="false"
        icon="fas fa-exclamation"
        colorIcon="error"
        [titulo]="language.messageTitle">
        <p>
          {{ txtErro }}
        </p>
        <button type="button" class="btnMain" (click)="fecharErro()">
          {{ language.buttonTryAgain }}
        </button>
      </app-message>
    </div>
  </div>
</ng-template>

<swal #swalConfirm [title]="language.swalTitle" icon="success"> </swal>

<app-modal [id]="idModal" [isScrollable]="true" [hasFooter]="false">
  <div header class="input-div">
    <input
      type="text"
      class="form-control"
      [placeholder]="language.placeholderSearch"
      [(ngModel)]="filtroInput"
      (ngModelChange)="search(filtroInput)" />
  </div>

  <button
    class="unidade-row"
    (click)="selecionarUnidade(unidade)"
    *ngFor="let unidade of unidades | filter : filtroInput : 'centerName'">
    <div class="unidade-row__header">
      <div class="header-name">
        <p
          class="centerName"
          appHighlight
          [searchedWord]="filtroInput"
          [content]="unidade.centerName"
          [classToApply]="'font-weight-bold'"
          [setTitle]="true">
          {{ unidade.centerName }}
        </p>

        <p class="ce" *ngIf="centerQueryParams.dce">
          {{ unidade.city }} <span *ngIf="unidade.state !== '     '">-</span> {{ unidade.state }}
        </p>
      </div>
      <div class="header-flags">
        <p
          *ngFor="let classSubject of unidade.classSubjects"
          [ngClass]="{
            matematica:
              classSubject.classSubject === 'Matemática' ||
              classSubject.classSubject === 'Matemáticas',
            portugues:
              classSubject.classSubject === 'Português' || classSubject.classSubject === 'Español',
            ingles:
              classSubject.classSubject === 'Inglês' || classSubject.classSubject === 'Inglés',
            japones:
              classSubject.classSubject === 'Nihongo' || classSubject.classSubject === 'Kokugo'
          }">
          {{ classSubject.classSubject.split('').slice(0, 1) }}
        </p>
      </div>
    </div>

    <div class="unidade-row__footer">
      <p class="centerId"><span>Center ID</span>{{ unidade.centerId }}</p>
      <p class="instructorId"><span>Instructor ID</span>{{ unidade.instructorId }}</p>
    </div>
  </button>
</app-modal>