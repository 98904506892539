import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AppComponent } from './app.component';
import { NgxMaskModule } from 'ngx-mask';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';

import { HeaderComponent } from './shared/header/header.component';
import { ModalComponent } from './shared/modal/modal.component';
import { MessageComponent } from './shared/message/message.component';
import { InputErrorComponent } from './shared/input-error/input-error.component';
import { LoadingComponent } from './shared/loading/loading.component';
import { LeadComponent } from './pages/lead/lead.component';
import { FilterPipe } from './pipes/filter.pipe';
import { HighlightDirective } from './directives/highlight.directive';
import { ConsentInterceptor } from './authentication/authenticate.interceptor'
import { InitServiceEnrollment } from './services/init.service';

export function initializeEnrollment(InitServiceEnrollment: InitServiceEnrollment) {
  return (): Promise<any> => {
    return InitServiceEnrollment.Init();
  }
}

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    ModalComponent,
    MessageComponent,
    InputErrorComponent,
    LoadingComponent,
    LeadComponent,
    FilterPipe,
    HighlightDirective
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgxMaskModule.forRoot(),
    SweetAlert2Module.forRoot()
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: initializeEnrollment,
      deps: [InitServiceEnrollment],
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ConsentInterceptor,
      multi: true
    },

  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
