// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  API: "https://eenrollmentapitst.kumon.com.br/api",
  username: "RCUser",
  password: "0I@m0NrD#2iN8m9sr$KNSi62@",
  appSecretKey: "81G8Xomj07-cc^iJ2lpH!l@g_PH55l3f27dLTH9D",

  // APIenrollment: 'https://eenrollmentapitst.kumon.com.br/api',
  // urlEnrollment: 'https://eenrollmenttst.kumon.com.br',
  // urlEnrollmentLA: 'https://eenrollmenttst.kumonla.com'
}

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
