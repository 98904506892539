import { Router } from '@angular/router'
import { Component, OnInit, ViewChild } from '@angular/core'
import { Validators, FormGroup, FormControl } from '@angular/forms'
import { ApisService } from 'src/app/services/apis.service'
import { AuthService } from 'src/app/services/auth.service'
import { Modal } from 'bootstrap'
import * as bootstrap from 'bootstrap'
import { SwalComponent } from '@sweetalert2/ngx-sweetalert2'
import * as moment from 'moment'

@Component({
  selector: 'app-lead',
  templateUrl: './lead.component.html',
  styleUrls: ['./lead.component.scss']
})
export class LeadComponent implements OnInit {

  isLoading: boolean = false
  isAuthenticated: boolean = true
  txtAuth: string = 'Carregando'
  txtErro: string = ''
  botaoCadastrarCarregando = false
  iconeBotaoCadastraLead = ''

  displayHash: string = ''
  chamadaOk = false

  idModal: string = 'modalUnidade'
  modal: Modal | undefined

  isCenterError = false
  unidades: any = []
  todasUnidades: any = []
  filtroInput = ''
  botaoCarregando = false
  iconeBotaoBuscarUnidade = 'fas fa-search'

  showIconBuscar = true
  btnLimparCenterID = false
  intructorId = ''
  createBy = ''
  createDate = ''
  centerQueryParams = {
    dce: true,
    justMol: true,
    centerId: ''
  }

  country = 'BR'
  unidadeInput = 'Buscar unidade'

  language = {
    prefix: '',
    title: 'Cadastro de Lead',
    labelCenter: 'Center ID',
    labelFullname: 'Nombre completo',
    labelPhone: 'Teléfono',
    labelEmail: 'E-mail',
    placeholderSearch: 'Filtrar por nombre de la unidad',
    buttonSearch: 'Búsqueda de unidad',
    buttonRegister: 'Registrar',
    buttonTryAgain: 'Intentar de nuevo',
    errorRequired: 'Información obligatoria.',
    errorFullname: 'Por favor, ingrese el nombre completo.',
    errorEmail: 'E-mail inválido',
    errorPhone: 'Teléfono incompleto.',
    errorCenter: 'Hubo un problema al obtener las unidades. Vuelva a intentarlo más tarde.',
    messageTitle: 'Encontramos un problema',
    swalTitle: 'Lead registrado con éxito!',
    phoneFormat: ''
  }


  @ViewChild('swalConfirm')
  public readonly swalConfirm!: SwalComponent

  formLead = new FormGroup({
    centerId: new FormControl('', [Validators.required]),
    nome: new FormControl('', [Validators.required, Validators.maxLength(60), Validators.pattern(/[a-zA-ZÀ-ž]+/)]),
    telefone: new FormControl('', [Validators.required, Validators.minLength(10)]),
    email: new FormControl('', [Validators.pattern(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)])
  })

  centerId$ = this.formLead.get('centerId')?.valueChanges.subscribe(
    res => {
      if (res !== '') {
        this.btnLimparCenterID = true
      } else {
        this.btnLimparCenterID = false
      }
    }
  )

  constructor(
    private api: ApisService,
    private authService: AuthService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.AuthenticateAPI()

    this.changeCountry()
  }

  changeCountry() {

    console.log(this.router.routerState.snapshot.url)

    switch (this.router.routerState.snapshot.url) {
      case '/cl':
        this.country = 'CL'
        this.unidadeInput = this.language.buttonSearch

        this.language = {
          ...this.language,
          prefix: '+56',
          phoneFormat: '0 0000-0000'
        }

        this.formLead.get('telefone')?.setValidators([Validators.required, Validators.minLength(9)])
        this.formLead.get('telefone')?.updateValueAndValidity()

        console.log('Chile')
        // console.log(this.language)
        // console.log('country', this.country)

        break


      case '/co':
        this.country = 'CO'
        this.unidadeInput = this.language.buttonSearch

        this.language = {
          ...this.language,
          prefix: '+57',
          phoneFormat: '(000) 0000000'
        }

        this.formLead.get('telefone')?.setValidators([Validators.required, Validators.minLength(10)])
        this.formLead.get('telefone')?.updateValueAndValidity()

        console.log('Colômbia')
        // console.log(this.language)
        // console.log('country', this.country)

        break

      case '/ar':
        this.country = 'AR'
        this.unidadeInput = this.language.buttonSearch

        this.language = {
          ...this.language,
          prefix: '+54',
          phoneFormat: '9999999999'
        }

        this.formLead.get('telefone')?.setValidators([Validators.required, Validators.minLength(5)])
        this.formLead.get('telefone')?.updateValueAndValidity()

        console.log('Argentina')
        // console.log(this.language)
        // console.log('country', this.country)

        break

      case '/bo':
        this.country = 'BO'
        this.unidadeInput = this.language.buttonSearch

        this.language = {
          ...this.language,
          prefix: '+591',
          phoneFormat: '999999999'
        }

        this.formLead.get('telefone')?.setValidators([Validators.required, Validators.minLength(7)])
        this.formLead.get('telefone')?.updateValueAndValidity()

        console.log('Bolívia')

        break

      case '/pe':
        this.country = 'PE'
        this.unidadeInput = this.language.buttonSearch

        this.language = {
          ...this.language,
          prefix: '+51',
          phoneFormat: '999999999'
        }

        this.formLead.get('telefone')?.setValidators([Validators.required, Validators.minLength(7)])
        this.formLead.get('telefone')?.updateValueAndValidity()

        console.log('Peru')

        break

      case '/uy':
        this.country = 'UY'
        this.unidadeInput = this.language.buttonSearch

        this.language = {
          ...this.language,
          prefix: '+598',
          phoneFormat: '999999999'
        }

        this.formLead.get('telefone')?.setValidators([Validators.required, Validators.minLength(5)])
        this.formLead.get('telefone')?.updateValueAndValidity()

        console.log('Uruguai')

        break

      default:
        console.log('Brasil')
        this.country = 'BR'

        this.language = {
          prefix: '+55',
          title: 'Cadastro de Lead',
          labelCenter: 'Center ID',
          labelFullname: 'Nome completo',
          labelPhone: 'Telefone',
          labelEmail: 'E-mail',
          placeholderSearch: 'Filtrar pelo nome da unidade',
          buttonSearch: 'Buscar unidade',
          buttonRegister: 'Cadastrar',
          buttonTryAgain: 'Tentar novamente',
          errorRequired: 'Campo obrigatório',
          errorFullname: 'Por favor, preencha o nome completo.',
          errorEmail: 'E-mail inválido',
          errorPhone: 'Telefone incompleto',
          errorCenter: 'Houve um problema ao buscar as unidades. Tente novamente mais tarde.',
          messageTitle: 'Encontramos um problema',
          swalTitle: 'Lead cadastrado com sucesso!',
          phoneFormat: '(00) 00000-0000||(00) 0000-0000'
        }
    }
  }

  AuthenticateAPI() {

    this.isLoading = true

    this.authService.authEnrollment().subscribe(
      res => {
        this.isLoading = false
      },
      err => {
        this.isAuthenticated = false
        this.isLoading = false
      }
    )
  }

  cadastraNovoLead() {

    this.botaoCadastrarCarregando = true
    this.iconeBotaoCadastraLead = 'fas fa-spinner'

    let dados = {
      "centerId": this.formLead.getRawValue().centerId?.padStart(8, '0'),
      "nome": this.api.removeSpaces(this.formLead.value.nome!),
      "telefone": this.api.removeSpaces(this.language.prefix + this.formLead.value.telefone),
      "email": this.api.removeSpaces(this.formLead.value.email!) || '',
      "statusId": 2,
      "leadSourceId": 3,
      "countryId": this.country,
      "instructorId": this.intructorId,
      "createBy": "Lead manual",
      "createDate": moment().toISOString(true)
    }

    console.log(dados)

    this.api.cadastraLead(dados).subscribe(
      res => {
        console.log(res.hash)
        this.swalConfirm.fire()
        this.botaoCadastrarCarregando = false
        this.iconeBotaoCadastraLead = ''
        this.limparForm()
      },
      err => {
        this.txtErro = err.error
        this.botaoCadastrarCarregando = false
        this.iconeBotaoCadastraLead = ''
        this.isAuthenticated = false
      }
    )
  }

  proximaPagina() {

    if (!this.formLead.valid) {
      this.formLead.markAllAsTouched()
    } else {
      this.cadastraNovoLead()
    }

  }

  buscarUnidades(e: Event) {

    this.botaoCarregando = true
    this.iconeBotaoBuscarUnidade = 'fas fa-spinner'

    if (this.unidades.length !== 0) {
      this.openModal()
    } else {

      this.api.unidades(this.centerQueryParams, this.country).subscribe(
        res => {
          this.isCenterError = false
          this.unidades = res
          this.todasUnidades = this.unidades
          this.openModal()
          console.log(this.unidades)
        },
        err => {
          this.botaoCarregando = false
          this.iconeBotaoBuscarUnidade = 'fas fa-search'
          this.isCenterError = true
        }
      )
    }
  }

  search(value: string): void {
    this.unidades = this.todasUnidades.filter((val: any) =>
      val.centerName.toLowerCase().includes(value)
    )
  }

  selecionarUnidade(unidade: any) {
    this.unidadeInput = unidade.centerName
    this.showIconBuscar = false
    this.intructorId = unidade.instructorId
    this.createBy = unidade.createBy
    this.createDate = unidade.createDate

    this.formLead.patchValue({
      centerId: unidade.centerId
    })

    this.modal?.hide()
    this.filtroInput = ''
  }

  openModal() {
    this.botaoCarregando = false
    this.iconeBotaoBuscarUnidade = 'fas fa-search'

    this.unidades = this.todasUnidades
    this.modal = new bootstrap.Modal(document.getElementById(this.idModal)!)
    this.modal.show()
  }

  limparForm() {
    this.formLead.reset()
    this.unidadeInput = this.language.buttonSearch
    this.showIconBuscar = true
    this.btnLimparCenterID = false
    this.filtroInput = ''
  }

  limparCenterId() {
    this.formLead.patchValue({
      centerId: ''
    })
    this.formLead.get('centerId')?.touched

    this.unidadeInput = this.language.buttonSearch
    this.filtroInput = ''
    this.showIconBuscar = true
  }

  fecharErro() {
    this.isAuthenticated = true
  }

  alphaOnly(e: any) {
    let regex = new RegExp("^[a-zA-ZÀ-ž ]+$")
    let str = String.fromCharCode(!e.charCode ? e.which : e.charCode)

    if (regex.test(str)) {
      return true
    }
    e.preventDefault()
    return false
  }

}