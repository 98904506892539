import { Injectable } from '@angular/core';
import { TokenService } from './token.service';
import { environment } from './../../environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private appSecretKey: string = environment.appSecretKey

  private readonly api = `${environment.API}/Authentication`

  user = {
    username: environment.username,
    password: environment.password,
  }

  constructor(
    private http: HttpClient,
    private tokenService: TokenService
  ) { }

  params = new HttpParams().append('appSecretKey', this.appSecretKey)

  authEnrollment(): Observable<any> {
    return (
      this.http.post<string>(`${this.api}/login`, this.user, { params: this.params })
        .pipe(
          map((data: any) => {
            this.tokenService.setToken(data.token)
            return data.token

          })
        )
    )
  }
}
