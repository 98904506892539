import { ActivatedRoute } from '@angular/router'
import { environment } from './../../environments/environment'
import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import { lead } from '../authentication/authenticate.interceptor'

@Injectable({
  providedIn: 'root'
})
export class ApisService {

  hash: string = ''
  validHashCode: boolean = true

  private readonly api = `${environment.API}`

  constructor(
    private http: HttpClient,
    private route: ActivatedRoute
  ) { }


  cadastraLead(body: any): Observable<any> {
    return this.http.post(`${this.api}/Lead`, body, { context: lead() })
  }

  unidades(params: any, country: string) {

    return this.http.get(`${this.api}/Branch/center/country/${country}`,
      {
        context: lead(),
        params: params
      }).pipe(
        map((unidades: any) => {
          return unidades.sort((a: any, b: any) => this.ordena(a.centerName, b.centerName))
        })
      )
  }

  ordena(a: any, b: any) {
    if (a > b) {
      return 1
    }
    if (a < b) {
      return -1
    }
    return 0
  }


  removeSpaces(text: string) {

    if (!text) return

    return text.trim().replace(/\s+/g, " ")
  }

}
