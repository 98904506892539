import { TokenService } from './token.service'
import { Injectable } from '@angular/core'
import { AuthService } from './auth.service'

@Injectable({
    providedIn: 'root'
})
export class InitServiceEnrollment {

    constructor(
        private authService: AuthService
    ) { }

    Init() {

        return new Promise<void>((resolve, reject) => {

            this.authService.authEnrollment()

            setTimeout(() => {
                resolve()
            }, 100)

        });
    }
}
