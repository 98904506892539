
import { Component, isDevMode, OnInit } from '@angular/core'
import { NavigationEnd, Router } from '@angular/router'

declare const gtag: Function

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent implements OnInit {

  constructor(public router: Router) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        gtag('config', 'G-JL968S2DT4', { 'page_path': event.urlAfterRedirects });
      }
    })
  }

  ngOnInit() {
    if (!isDevMode()) {
      console.log = function () { };
    }
  }

}
