import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpContextToken,
  HttpContext
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { TokenService } from '../services/token.service'


const LEAD = new HttpContextToken(() => false)

export function lead() {
  return new HttpContext().set(LEAD, true)
}

@Injectable()
export class ConsentInterceptor implements HttpInterceptor {

  constructor(
    private tokenService: TokenService
  ) { }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {


    if (request.context.get(LEAD)) {

      const headersEnrollment = request.clone({
        headers: request.headers
          .set('Authorization', `Bearer ${sessionStorage.getItem('leadToken')}`)
          .set('Content-Type', 'application/json')
          .set('Access-Control-Allow-Credentials', 'true')
        // .set('Access-Control-Allow-Origin', '*')
      })

      return next.handle(headersEnrollment)
    }

    const headers = request.clone({
      headers: request.headers
        .set('Authorization', `Bearer ${this.tokenService.getToken()}`)
        .set('Content-Type', 'application/json')
        .set('Access-Control-Allow-Credentials', 'true')
      // .set('Access-Control-Allow-Origin', '*')
    })

    return next.handle(headers)
  }
}
